<script>
import Modal from "@/components/shared/layout/Modal.vue";
import NoticePreview from "@/components/notices/NoticePreview.vue";
import { v4 as uuid } from "uuid";

export default {
  name: "newNoticeModal",
  components: { NoticePreview, Modal },
  data() {
    return {
      newNotice: {
        noticeId: "",
        title: "",
        color: "green",
        startTs: "",
        endTs: "",
        toggleable: false,
        messageHtml: `<p></p>`,
      },
      error: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    publishNotice() {
      const notice = {
        ...this.newNotice,
        startTs: this.convertTime(this.newNotice.startTs),
        endTs: this.newNotice.endTs
          ? this.convertTime(this.newNotice.endTs)
          : "",
      };
      if (
        this.newNotice.title &&
        this.newNotice.startTs &&
        this.newNotice.messageHtml
      ) {
        this.$emit("publish-notice", notice);
        this.$emit("close-modal");
      } else {
        this.error = true;
      }
    },
    convertTime(time) {
      const newTime = time.split("T");
      return `${newTime[0]} ${newTime[1]}:00`;
    },
  },
  mounted() {
    this.newNotice.noticeId = uuid();
  },
};
</script>

<template>
  <modal title="New Notice" @close-modal="closeModal">
    <div class="new-notice">
      <table>
        <tr>
          <td><label for="new-title">Title</label></td>
          <td>
            <input id="new-title" type="text" v-model="newNotice.title" />
          </td>
        </tr>
        <tr>
          <td>Color</td>
          <td>
            <div class="color-picker">
              <input
                type="radio"
                name="update-color"
                id="color-green"
                value="green"
                v-model="newNotice.color"
              />
              <label for="color-green"></label>
              <input
                type="radio"
                name="update-color"
                id="color-yellow"
                value="yellow"
                v-model="newNotice.color"
              />
              <label for="color-yellow"></label>
              <input
                type="radio"
                name="update-color"
                id="color-red"
                value="red"
                v-model="newNotice.color"
              />
              <label for="color-red"></label>
              <input
                type="radio"
                name="update-color"
                id="color-blue"
                value="blue"
                v-model="newNotice.color"
              />
              <label for="color-blue"></label>
              <span>{{ newNotice.color }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><label for="new-start-time">Start Time</label></td>
          <td>
            <input
              id="new-start-time"
              type="datetime-local"
              v-model="newNotice.startTs"
            />
          </td>
        </tr>
        <tr>
          <td><label for="new-end-time">End Time</label></td>
          <td>
            <input
              id="new-end-time"
              type="datetime-local"
              v-model="newNotice.endTs"
            />
          </td>
        </tr>
        <tr>
          <td><label for="new-toggle"></label>Toggleable</td>
          <td>
            <input
              type="checkbox"
              id="new-toggle"
              v-model="newNotice.toggleable"
            />
            <label for="new-toggle"></label>
          </td>
        </tr>
        <tr class="textarea">
          <td><label for="new-message">Message</label></td>
          <td>
            <textarea id="new-message" v-model="newNotice.messageHtml" />
          </td>
        </tr>
        <tr>
          <td>
            <button
              class="uik-btn__base btn btn-primary"
              @click="publishNotice"
            >
              Publish
            </button>
          </td>
          <td>
            <h3>Preview</h3>
            <NoticePreview
              :color="newNotice.color"
              :message="newNotice.messageHtml"
            />
          </td>
        </tr>
      </table>
      <div class="error" v-if="error">
        <p>
          Please make sure that the notice contains a Title, a Start Time and a
          Message
        </p>
      </div>
    </div>
  </modal>
</template>

<style scoped lang="scss">
$black: #000000;
$green: #38b249;
$red: #e6492d;
$yellow: #f6ab2f;
$blue: #1665d8;

.color-picker {
  display: flex;
  align-items: center;

  input[type="radio"] {
    + label {
      display: inline-block;
      width: 17px;
      height: 17px;
      background: $black;
      border-radius: 17px;
      margin-right: 20px;
    }

    &[value="green"] {
      + label {
        background: $green;
      }
    }

    &[value="red"] {
      + label {
        background: $red;
      }
    }

    &[value="yellow"] {
      + label {
        background: $yellow;
      }
    }

    &[value="blue"] {
      + label {
        background: $blue;
      }
    }

    &:checked {
      + label {
        border: 2px solid $black;
      }
    }
  }
}

input[type="checkbox"] {
  + label {
    display: flex;
    width: 14px;
    height: 14px;
    border: 1px solid #dedede;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  &:checked + label:before {
    content: "✓";
  }
}

input[type="text"],
input[type="datetime-local"] {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 4px;
}

textarea {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 15px 10px;
  display: block;
  min-height: 50px;
  resize: vertical;
  height: 100px;
}

.new-notice {
  tr {
    border-bottom: 1px solid #dedede;

    &:last-child {
      border-bottom: none;
    }

    &.textarea {
      td {
        height: auto;
        padding: 5px 0;
      }
    }
  }

  td {
    height: 50px;
    vertical-align: middle;

    &:first-child {
      width: 30%;
    }
  }
}

.error {
  color: $red;
  background: transparentize($red, 0.9);
  border: 1px solid $red;
  padding: 30px;
  display: inline-block;
}
</style>
