<script>
import Modal from "@/components/shared/layout/Modal.vue";
import NoticePreview from "@/components/notices/NoticePreview.vue";

export default {
  name: "EditNoticeModal",
  components: { NoticePreview, Modal },
  props: {
    notice: Object,
  },
  data() {
    return {
      newNotice: {},
    };
  },
  methods: {
    updateNotice() {
      const noticeUpdate = {
        ...this.newNotice,
        startTs: this.convertTime(this.newNotice.startTs),
        endTs: this.convertTime(this.newNotice.endTs),
      };
      this.$emit("update-notice", noticeUpdate);
    },
    closeUpdate() {
      this.$emit("close-update");
    },
    convertTime(time) {
      if (time && time.includes("T")) {
        const newTime = time.split("T");
        return `${newTime[0]} ${newTime[1]}:00`;
      } else {
        return time;
      }
    },
  },
  beforeMount() {
    this.newNotice = { ...this.notice };
  },
};
</script>

<template>
  <modal :title="newNotice.noticeId" @close-modal="closeUpdate">
    <div class="notice-update">
      <table>
        <tr>
          <td>Title</td>
          <td><input type="text" v-model="newNotice.title" /></td>
        </tr>
        <tr>
          <td>Color</td>
          <td>
            <div class="color-picker">
              <input
                type="radio"
                name="update-color"
                id="color-green"
                value="green"
                v-model="newNotice.color"
              />
              <label for="color-green"></label>
              <input
                type="radio"
                name="update-color"
                id="color-yellow"
                value="yellow"
                v-model="newNotice.color"
              />
              <label for="color-yellow"></label>
              <input
                type="radio"
                name="update-color"
                id="color-red"
                value="red"
                v-model="newNotice.color"
              />
              <label for="color-red"></label>
              <input
                type="radio"
                name="update-color"
                id="color-blue"
                value="blue"
                v-model="newNotice.color"
              />
              <label for="color-blue"></label>
              <span>{{ newNotice.color }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Start Time</td>
          <td><input type="datetime-local" v-model="newNotice.startTs" /></td>
        </tr>
        <tr>
          <td>End Time</td>
          <td><input type="datetime-local" v-model="newNotice.endTs" /></td>
        </tr>
        <tr>
          <td>Toggleable</td>
          <td>
            <input
              type="checkbox"
              v-model="newNotice.toggleable"
              id="toggleable-update"
            />
            <label for="toggleable-update"></label>
          </td>
        </tr>
        <tr class="textarea">
          <td>Message HTML</td>
          <td>
            <textarea v-model="newNotice.messageHtml"></textarea>
          </td>
        </tr>
        <tr>
          <td>
            <button class="uik-btn__base btn btn-primary" @click="updateNotice">
              Update
            </button>
          </td>
          <td>
            <h3>Preview</h3>
            <NoticePreview
              :color="newNotice.color"
              :message="newNotice.messageHtml"
              :toggleable="newNotice.toggleable"
            />
          </td>
        </tr>
      </table>
    </div>
  </modal>
</template>

<style scoped lang="scss">
$black: #000000;
$green: #38b249;
$red: #e6492d;
$yellow: #f6ab2f;
$blue: #1665d8;

.color-picker {
  display: flex;
  align-items: center;

  input[type="radio"] {
    + label {
      display: inline-block;
      width: 17px;
      height: 17px;
      background: $black;
      border-radius: 17px;
      margin-right: 20px;
    }

    &[value="green"] {
      + label {
        background: $green;
      }
    }

    &[value="red"] {
      + label {
        background: $red;
      }
    }

    &[value="yellow"] {
      + label {
        background: $yellow;
      }
    }

    &[value="blue"] {
      + label {
        background: $blue;
      }
    }

    &:checked {
      + label {
        border: 2px solid $black;
      }
    }
  }
}

input[type="checkbox"] {
  + label {
    display: flex;
    width: 14px;
    height: 14px;
    border: 1px solid #dedede;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
  &:checked + label:before {
    content: "✓";
  }
}

input[type="text"],
input[type="datetime-local"] {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 4px;
}

textarea {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 15px 10px;
  display: block;
  min-height: 50px;
  resize: vertical;
  height: 100px;
}

h3 {
  margin: 15px 0 5px;
}

.notice-update {
  tr {
    border-bottom: 1px solid #dedede;

    &:last-child {
      border-bottom: none;
    }

    &.textarea {
      td {
        height: auto;
        padding: 5px 0;
      }
    }
  }

  td {
    height: 50px;
    vertical-align: middle;

    &:first-child {
      width: 30%;
    }
  }
}
</style>
