<script>
import NoticesList from "@/components/notices/NoticesList.vue";
import NoticeModal from "@/components/notices/NoticeModal.vue";
import NewNoticeModal from "@/components/notices/NewNoticeModal.vue";

export default {
  name: "notices",
  components: { NewNoticeModal, NoticeModal, NoticesList },
  data() {
    return {
      showOnlyActive: false,
      noticeData: [],
      notice: null,
      displayNewNotice: false,
    };
  },
  methods: {
    createNewNotice() {
      this.displayNewNotice = true;
    },
    closeNewNotice() {
      this.displayNewNotice = false;
    },
    toggleNotice(notice) {
      this.notice = notice;
    },
    updateNotice(notice) {
      const index = this.noticeData.findIndex(
        (item) => item.noticeId === notice.noticeId
      );
      this.noticeData.splice(index, 1, notice);
      this.fetchData("PUT", this.noticeData);
    },
    deleteNotice(id) {
      const index = this.noticeData.findIndex((item) => item.noticeId === id);
      this.noticeData.splice(index, 1);
      this.fetchData("PUT", this.noticeData);
      this.toggleNotice(null);
    },
    publishNewNotice(notice) {
      this.noticeData.push(notice);
      this.fetchData("PUT", this.noticeData);
    },
    async fetchData(method, data) {
      try {
        const response = await fetch(
          process.env.VUE_APP_API_LOCATION + "/notices",
          {
            method,
            body: method === "PUT" ? JSON.stringify(data) : null,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Response failed with status ${response.status}`);
        }
        if (method === "GET") {
          this.noticeData = await response.json();
        } else {
          console.log("Data was successfully posted to notices");
        }
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  mounted() {
    this.fetchData("GET");
  },
};
</script>

<template>
  <div>
    <div class="view-header-wrap uik-entity-list-header">
      <h2 class="title">Notices</h2>

      <div>
        <input type="checkbox" id="show-active" v-model="showOnlyActive" />
        <label for="show-active">Show active Notices</label>
      </div>

      <div class="right-aligned popover-wrapper">
        <button
          @click.stop="createNewNotice"
          type="button"
          class="uik-btn__base btn btn-primary"
          tabindex="-1"
        >
          New notices
        </button>
      </div>
    </div>
    <notices-list
      :notices="noticeData"
      :displayActive="showOnlyActive"
      @view-notice="toggleNotice"
    />

    <notice-modal
      v-if="notice"
      :notice="notice"
      @close-notice="toggleNotice"
      @update-notice="updateNotice"
      @delete-notice="deleteNotice"
    />
    <new-notice-modal
      v-if="displayNewNotice"
      @publish-notice="publishNewNotice"
      @close-modal="closeNewNotice"
    />
  </div>
</template>

<style scoped lang="scss">
input[type="checkbox"] {
  + label {
    display: flex;
    align-items: center;
    margin-left: 30px;

    &:before {
      content: "";
      display: flex;
      width: 14px;
      height: 14px;
      border: 1px solid #dedede;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }

  &:checked + label:before {
    content: "✓";
  }
}
</style>
