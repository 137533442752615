<script>
import ElementList from "@/components/shared/layout/ElementList.vue";

export default {
  name: "noticesList",
  components: { ElementList },
  props: {
    notices: Array,
    displayActive: Boolean,
  },
  data() {
    return {
      listColumns: [
        {
          header: "Title",
          key: "title",
          field: "title",
        },
        {
          header: "Start time",
          key: "startTs",
          field: "startTs",
        },
        {
          header: "End time",
          key: "endTs",
          field: "endTs",
        },
        {
          header: "Color",
          key: "color",
          field: "color",
        },
        {
          header: "Active",
          key: "active",
          field: "active",
        },
      ],
    };
  },
  computed: {
    noticesWithActive() {
      const withActive = this.notices.map((notice) => {
        return {
          ...notice,
          active: this.isActive(notice.startTs, notice.endTs),
        };
      });
      return this.displayActive
        ? withActive.filter((notice) => notice.active)
        : withActive;
    },
  },
  methods: {
    viewNotice(notice) {
      this.$emit("view-notice", notice);
    },
    isActive(startTs, endTs) {
      const now = new Date().getTime();
      const start = new Date(startTs).getTime();
      const end = new Date(endTs).getTime();
      return (!end && start < now) || (start < now && end > now);
    },
  },
};
</script>

<template>
  <element-list
    :items="noticesWithActive"
    :columns="listColumns"
    item-key="noticeId"
    @on-item-clicked="viewNotice"
    :show-pagination="false"
  />
</template>
