<script>
export default {
  name: "NoticePreview",
  props: {
    message: String,
    color: String,
    toggleable: Boolean,
  },
};
</script>

<template>
  <div class="notice-preview" :class="`notice-preview--${color}`">
    <div v-html="message" />
    <i class="dashboard-icon icon-close" v-if="toggleable"></i>
  </div>
</template>

<style scoped lang="scss">
$black: #000000;
$green: #38b249;
$red: #e6492d;
$yellow: #f6ab2f;
$blue: #1665d8;

.notice-preview {
  padding: 12px 20px;
  color: $black;
  background: transparentize($black, 0.9);
  border: 1px solid $black;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--green {
    color: $green;
    background: transparentize($green, 0.9);
    border: 1px solid $green;
  }

  &--red {
    color: $red;
    background: transparentize($red, 0.9);
    border: 1px solid $red;
  }

  &--yellow {
    color: $yellow;
    background: transparentize($yellow, 0.9);
    border: 1px solid $yellow;
  }

  &--blue {
    color: $blue;
    background: transparentize($blue, 0.9);
    border: 1px solid $blue;
  }

  .dashboard-icon {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
}
</style>
