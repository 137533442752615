<script>
import Modal from "@/components/shared/layout/Modal.vue";
import NoticePreview from "@/components/notices/NoticePreview.vue";
import EditNoticeModal from "@/components/notices/EditNoticeModal.vue";

export default {
  name: "noticeModal",
  components: {
    EditNoticeModal,
    NoticePreview,
    Modal,
  },
  props: {
    notice: Object,
  },
  data() {
    return {
      toolbarSettings: {
        toolbarTitle: this.notice.title,
        actions: [
          {
            name: "Edit",
          },
          {
            name: "Retract Notice",
          },
          {
            name: "Delete Notice",
          },
        ],
      },
      editNotice: false,
      viewNotice: this.notice,
    };
  },
  computed: {
    isActive() {
      const now = new Date().getTime();
      const start = new Date(this.viewNotice.startTs).getTime();
      const end = new Date(this.viewNotice.endTs).getTime();
      return (!end && start < now) || (start < now && end > now);
    },
  },
  methods: {
    updateNotice(notice) {
      this.viewNotice = notice;
      this.$emit("update-notice", notice);
      this.editNotice = false;
    },
    closeModal() {
      this.$emit("close-notice", null);
    },
    toggleEditNotice(open) {
      this.editNotice = open;
    },
    retractNotice() {
      if (confirm("This will set the end date to now. Are you sure")) {
        const timeNow = new Date();
        const year = timeNow.getFullYear();
        const month = timeNow.getMonth() + 1;
        const day = timeNow.getDate();
        const hour = timeNow.getHours();
        const minute = timeNow.getMinutes();
        const second = timeNow.getSeconds();
        this.viewNotice.endTs = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        this.$emit("update-notice", this.viewNotice);
      }
    },
    toolbarClicked(action) {
      switch (action) {
        case "Edit":
          this.toggleEditNotice(true);
          break;
        case "Retract Notice":
          this.retractNotice();
          break;
        case "Delete Notice":
          this.deleteNotice();
      }
    },
    deleteNotice() {
      if (confirm("This will permanently delete this notice. Are you sure?")) {
        this.$emit("delete-notice", this.viewNotice.noticeId);
      }
    },
  },
};
</script>

<template>
  <modal
    title="Notice"
    :toolbar="true"
    :toolbar-settings="toolbarSettings"
    @toolbar-action-clicked="toolbarClicked"
    @close-modal="closeModal"
  >
    <div class="info-table">
      <table class="info-table">
        <tr>
          <td>Notice ID</td>
          <td>{{ viewNotice.noticeId }}</td>
        </tr>
        <tr>
          <td>Color</td>
          <td>
            <span class="color" :class="`color--${viewNotice.color}`">
              {{ viewNotice.color }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Start Time</td>
          <td>{{ viewNotice.startTs }}</td>
        </tr>
        <tr>
          <td>End Time</td>
          <td>{{ viewNotice.endTs }}</td>
        </tr>
        <tr>
          <td>Toggleable</td>
          <td>{{ viewNotice.toggleable }}</td>
        </tr>
        <tr>
          <td>Message</td>
          <td>{{ viewNotice.messageHtml }}</td>
        </tr>
        <tr>
          <td>Active</td>
          <td>{{ isActive }}</td>
        </tr>
      </table>
    </div>
    <h3>Preview</h3>
    <notice-preview
      :color="viewNotice.color"
      :message="viewNotice.messageHtml"
      :toggleable="viewNotice.toggleable"
    />
    <edit-notice-modal
      :notice="notice"
      v-if="editNotice"
      @close-update="toggleEditNotice(false)"
      @update-notice="updateNotice"
    />
  </modal>
</template>

<style scoped lang="scss">
$black: #000000;
$green: #38b249;
$red: #e6492d;
$yellow: #f6ab2f;
$blue: #1665d8;

.info-table {
  border: 1px solid #dedede;
  border-radius: 15px;

  table {
    border: none;
    margin: 0;

    tr {
      border-bottom: 1px solid #efefef;

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    td {
      padding: 14px 30px;
    }
  }
}

h3 {
  margin: 15px 0 5px;
}

.color {
  display: inline-flex;
  align-items: center;

  &:before {
    display: block;
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 17px;
    background: $black;
    margin-right: 10px;
  }

  &--green:before {
    background: $green;
  }

  &--red:before {
    background: $red;
  }

  &--yellow:before {
    background: $yellow;
  }

  &--blue:before {
    background: $blue;
  }
}
</style>
